
// Some js

'use strict';



var _win = $(window);



  const quizContainer = document.getElementById('quiz'); 
  let btnAttr = " ";
  let ctaMore;
  

  function buildQuiz(){ 
    
    $.ajax({
        url: jurl,
        dataType: 'json',
        method: 'GET',
        success: function (myQuestions) {
            
            const output = [];
  
        // for each question...
        myQuestions.forEach(
        (currentQuestion, questionNumber) => {
    
            // variable to store the list of possible answers 
            const answers = [];
            let answer;
            let imgUrl = imgPath;
            let button;
            let goback = "";

            if (questionNumber + 1  < myQuestions.length){
            button =  `<button type="button" class="cta-btn cta-brown txt-upper" data-toggle="modal" data-dismiss="modal" data-target="#popup-step-${questionNumber + 1}" disabled>
            ${ctaMore}
                        </button> `
            } else {
            button = `<button type="button" onclick="resultQuiz();" class="cta-btn cta-brown txt-upper cta-test" ${btnAttr} disabled>
            ${ctaMore}
                </button> `
            }

            if (questionNumber != 0){
                goback =  `<button type="button" class="back-btn" data-toggle="modal" data-dismiss="modal" data-target="#popup-step-${questionNumber - 1}">
                back
            </button> `
            }
            // and for each available answer...
            for(answer in currentQuestion.answers){
    
            var obj =currentQuestion.answers[answer];
            var objVal =currentQuestion.answers[answer].value; 
             
                answers.push(
                `
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="answer-${answer}" id="answer-${answer}-${questionNumber}" data-omni="${objVal.omni}" data-wave="${objVal.wave}" data-steam="${objVal.steam}">
                    <label class="form-check-label" for="answer-${answer}-${questionNumber}">
                    ${obj.answer}
                    </label>
                </div>
                `
                );
            
            
            }
    
            // add this question and its answers to the output
            output.push(
            `
            <div class="modal modal-simply fade" id="popup-step-${questionNumber}" tabindex="-1" role="dialog" aria-labelledby="popup-test" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-white">
                    <div class="modal-wrap">
                            <button type="button" class="close bg-brown txt-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-body">
                            <div class="question-image">
                            <img src="${imgUrl}/${currentQuestion.image}">
                            </div>
                            <div class="question-wrap">
                                <div class="question">${currentQuestion.question}</div>
                                <div class="answer">
                                ${answers.join('')}
                            </div>
                                ${button}
                                    <div class="count-step">${questionNumber + 1}/${myQuestions.length}</div>
                                    ${goback}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
            `
            );
        });
        quizContainer.innerHTML = output.join('');     
        },
        error: function (xhr) {
            return xhr
        }
        
    });
    
  }

  function resultQuiz(){
    let checkChecked = $('#quiz .form-check-input:checked');

    const omniVal = [];
    const waveVal = [];
    const steamVal = [];

    if (checkChecked.length) {
        checkChecked.each(function(){
            omniVal.push($(this).data('omni'));
            waveVal.push($(this).data('wave'));
            steamVal.push($(this).data('steam'));
          });
      
          let omniSum = omniVal.reduce((previous, next) => {
              return previous + next;
          });
          let waveSum = waveVal.reduce((previous, next) => {
              return previous + next;
          });
          let steamSum = steamVal.reduce((previous, next) => {
              return previous + next;
          });
      
          $(".modal").modal('hide');
          for (var i=0; i<checkChecked.length; i++)  {
            if (checkChecked[i].type == 'checkbox')   {
                checkChecked[i].checked = false;
            }
          }
      
          if (omniSum > waveSum && omniSum > steamSum) {
              
              $("#popup-result-omni").modal('show');
          } else if (waveSum > omniSum   && waveSum > steamSum){
             
              $("#popup-result-wave").modal('show');
          } else {
           
              $("#popup-result-steam").modal('show');
          }
    } else {
        $(".modal").modal('hide');
        for (var i=0; i<checkChecked.length; i++)  {
        if (checkChecked[i].type == 'checkbox')   {
            checkChecked[i].checked = false;
            }
        }
        $("#popup-result-omni").modal('show');
    }
    

    
  }
 
  $(document).ready(function () {
      
      buildQuiz();
      $(document).on('click', '#quiz .form-check-input' ,function() {
        if($("#quiz .modal .form-check-input:checked ").length){
            $("#quiz .modal").each(function(){
                if($(this).find(".form-check-input:checked").length) {
                    
                    $(this).find('.cta-btn').prop('disabled', false);
                } else if ($(this).not(".form-check-input:checked").length){
                    $(this).find('.cta-btn').prop('disabled', true);
                }
            })
        } 
       
      });
      
  });